<div class="form-container">
  <form class="form-wrapper" [formGroup]="detailForm" *ngIf="!wifiAccessCreated">
    <div class="form-row">
      <div class="form-group col-md-6">
        <app-custom-input label="{{'PatientAccessCode' | translate }}"
                      controlName="accessCode"
                      [parentForm]="detailForm"
                      required="true">
        </app-custom-input>
      </div>
      <div class="button-next">
        <button type="submit" [disabled]="!detailForm.valid" class="btn btn-primary no-icon secondary validate-button"
                (click)="tryConnection()">
          {{ "WifiLogin" | translate }}
        </button>
      </div>
    </div>
  </form>
  <form #hheWifiAuthForm name="wifiAuthForm" ngNoForm method="post" [action]="wifiApAuthUrl"  *ngIf="wifiAccessCreated">
    <input type="hidden" name="username" [value]="wifiUsername"/>
    <input type="hidden" name="password" [value]="wifiPassword"/>
    <input type="hidden" name="dst" [value]="wifiWelcomeUrl"/>
    <input type="hidden" name="popup" value="true"/>
    <div class="button-next">
      <button name="submit" type="submit"  class="btn btn-primary secondary validate-button">
        {{ "WifiLogin" | translate }}
      </button>
    </div>
  </form>
</div>
