import { Component, OnInit, Input } from "@angular/core"
import {FormGroup} from "@angular/forms"
import {ScreenService} from "../../shared/services/screen.service"
import {Observable} from "rxjs"

@Component({
  selector: "app-date-picker",
  templateUrl: "./date-picker.component.html",
  styleUrls: ["./date-picker.component.scss"]
})
export class DatePickerComponent implements OnInit {
  isKiosk$: Observable<boolean>

  @Input() controlForm: string
  @Input() parentForm: FormGroup
  @Input() label
  @Input() required = false

  constructor(
    private screenService: ScreenService,
  ) { }

  ngOnInit(): void {
    this.isKiosk$ = this.screenService.isKiosk()
  }
}
