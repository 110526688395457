<div *ngIf="!(isKiosk$ | async)" [formGroup]="parentForm" class="humehealth__form-field">
  <p class="humehealth__label">{{ label }}</p>
  <div class="humehealth__input-container">
    <input class="humehealth__input" matInput [formControlName]="controlForm"
          [matDatepicker]="picker" (click)="picker.open()" [required]="required">
    <mat-datepicker-toggle class="humehealth__input-suffix" matSuffix [for]="picker"></mat-datepicker-toggle>
  </div>
  <mat-datepicker #picker startView="year"></mat-datepicker>
</div>
<app-custom-input *ngIf="(isKiosk$ | async)"
                  [label]="label"
                  [controlName]="controlForm"
                  [parentForm]="parentForm"
                  matKeyboard="Numeric"
                  type="date"
                  required="true">
</app-custom-input>
