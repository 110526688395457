<div class="humehealth">
  <div class="humehealth__body">
    <div class="humehealth__header">
      <app-customer-logo></app-customer-logo>
    </div>
    <div class="humehealth__content">
      <div class="humehealth__title">{{ 'WhatDoYouWant' | translate }}</div>
      <div class="humehealth__sub-title">{{ 'SelectChoice' | translate }}</div>
      <div class="humehealth__home">
        <div class="humehealth__home__entry" *ngIf="institution.manageTv">
          <div class="humehealth__home__entry__label">{{ 'AccessTelevisionChannels' | translate }}</div>
          <div class="humehealth__home__entry__card" (click)="goToTvOpenPage()">
            <img src="assets/images/tv-icon.svg" alt="Ouvrir">
            <div class="humehealth__label">{{ 'ToOpen' | translate }}<span>{{ 'TVRights' | translate }}</span></div>
          </div>
        </div>
        <div class="humehealth__home__entry" *ngIf="institution.manageTv">
          <div class="humehealth__home__entry__label">{{ 'YouStayLonger' | translate }}</div>
          <div class="humehealth__home__entry__card" (click)="goToTvExtendPage()">
            <img src="assets/images/prolonger-icon.svg" alt="Prolonger">
            <div class="humehealth__label">{{ 'Extend' | translate }}<span>{{ 'TVRights' | translate }}</span></div>
          </div>
        </div>
        <div class="humehealth__home__entry" *ngIf="canOrderHeadset">
          <div class="humehealth__home__entry__label">{{ 'OrderHeadset' | translate }}</div>
          <div class="humehealth__home__entry__card" (click)="goToHeadsetPage()">
            <img src="assets/images/order-headset.svg" alt="Commander casque">
            <div class="humehealth__label">{{ 'Order' | translate }}<span>{{ 'Headset' | translate }}</span></div>
          </div>
        </div>
        <div class="humehealth__home__entry" *ngIf="institution.manageTv">
          <div class="humehealth__home__entry__label">{{ 'YouChangeRoom' | translate }}</div>
          <div class="humehealth__home__entry__card" (click)="goToTvTransferPage()">
            <img src="assets/images/transfer-icon.svg" alt="Ouvrir">
            <div class="humehealth__label">{{ 'ToTransfer' | translate }}<span>{{ 'TVRights' | translate }}</span></div>
          </div>
        </div>
        <div class="humehealth__home__entry" *ngIf="institution.manageWifi">
          <div class="humehealth__home__entry__label">{{ 'AccessWifi' | translate }}</div>
          <div class="humehealth__home__entry__card" (click)="goToOpenWifiPage()">
            <img src="assets/images/transfer-icon.svg" alt="Ouvrir">
            <div class="humehealth__label">{{ 'ToRequest' | translate }}<span>{{ 'WifiAccessCode' | translate }}</span></div>
          </div>
        </div>
        <div class="humehealth__home__entry" *ngIf="institution.manageWifi && sessionId && !(isKiosk$ | async)">
          <div class="humehealth__home__entry__label">{{ 'ConnectWifi' | translate }}</div>
          <div class="humehealth__home__entry__card" (click)="goToConnectToWifiPage()">
            <img src="assets/images/transfer-icon.svg" alt="Ouvrir">
            <div class="humehealth__label">{{ 'ToConnect' | translate }}<span>{{ 'ToWifi' | translate }}</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
