import {HttpBackend, HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HttpServiceWithoutInterceptors {

  private httpClient: HttpClient;

  constructor(
    private handler: HttpBackend,
  ) {
    this.httpClient = new HttpClient(handler);
  }

  getHttpClient(): HttpClient {
    return this.httpClient
  }
}
