import { Component, OnDestroy, OnInit } from "@angular/core"
import {ActivatedRoute, Params, Router} from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import {Observable, Subscription} from "rxjs"
import { filter, mergeMap, take } from "rxjs/operators"
import { ProductType } from "src/app/shared/interfaces/product"
import { InstitutionService } from "src/app/shared/services/institution.service"
import { SubscriptionActionType, SubscriptionType } from "../../shared/constants/enum"
import { Institution } from "../../shared/interfaces/institution"
import { ConfigService } from "../../shared/services/config.service"
import {ScreenService} from "../../shared/services/screen.service"
import { AbstractControl, FormControl, FormGroup } from "@angular/forms"
import { KeyboardInputService } from "../keyboard/keyboard-input.service"

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})

export class HomeComponent implements OnInit, OnDestroy {
  institution = {} as Institution
  subscriptions: Subscription = new Subscription()
  canOrderHeadset = false
  sessionId = null
  isKiosk$: Observable<boolean>

  detailForm: FormGroup = new FormGroup({
    lastName: new FormControl("")
  })

  constructor(
    private institutionService: InstitutionService,
    public translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private configService: ConfigService,
    private screenService: ScreenService,
  ) { }

  ngOnInit(): void {
    this.isKiosk$ = this.screenService.isKiosk()
    this.initInstitution()
  }

  initInstitution(): void {
    this.institutionService.getInstitution().subscribe(institution => {
      this.configService.setCustomerId(institution.customerId)
      this.institution = institution
      this.canOrderHeadset = institution.productTypes != null && institution.productTypes.includes(ProductType.HEADSET)
      this.initSession()
    })
  }

  initSession(): void {
    this.subscriptions.add(
      this.route.params.subscribe(params => {
          if (params.sid) {
            this.configService.setSessionId(params.sid)
            this.institutionService.setWifiSessionInstitutionId(params.sid)
          }
          this.sessionId = this.configService.getSessionId()
        }
      )
    )
  }

  public goToTvOpenPage(): void {
    this.router.navigateByUrl("subscription/" + SubscriptionType.TV + "/" + SubscriptionActionType.OPEN)
  }

  public goToTvTransferPage(): void {
    this.router.navigate(["transfer"])
  }

  public goToTvExtendPage(): void {
    this.router.navigate(["extend"])
  }

  public goToHeadsetPage(): void {
    this.router.navigate(["headset"])
  }

  public goToOpenWifiPage(): void {
    this.router.navigateByUrl("subscription/" + SubscriptionType.WIFI + "/" + SubscriptionActionType.OPEN)
  }

  public goToConnectToWifiPage(): void {
    this.router.navigate(["wifi-connection"])
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }
}
